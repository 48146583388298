import React from "react"
import ReactDOM from "react-dom"

import {Formik, Form, Field, ErrorMessage } from "formik"
import Recaptcha from 'react-recaptcha'

import Layout from "../components/layout"
import SEO from "../components/seo"


let recaptchaInstance
const API_URL = 'https://c8hooem1uf.execute-api.ca-central-1.amazonaws.com/default/HSCIWeb2Go'


const state = {
  state:0,
  states:{
    "READY":0,
    "SUBMITTED":1
  },
  responses:{
    success:200,
    error:400
  }
}

const toggleTrade = (e) => {
 document.getElementById('contact-us-form').classList = e.target.value==='no'?'non-trade':'trade'
}

const onFocus = (e) => {
  if(!e.target.parentNode.classList.contains('selected')){
    e.target.parentNode.classList.add('selected')
  }
  if(e.target.tagName==='LABEL')
    e.target.parentNode.lastChild.focus()

}


const JSON_FIELDS = {
  "name": "fullName",
  "email": "email",
  "phone": "phone",
  "text": "message"
}


class ContactForm extends React.Component{
  constructor(props) {
    super(props)

  }


  componentDidMount() {

  }

  componentWillUnmount() {
    // try{
    //   ReactDOM.unmountComponentAtNode(document.getElementById('g-recaptcha'))
    // } catch (e) {
    //   console.log('COULD NOT UNMOUNT')
    //   console.log(e)
    // }
  }

  render() {
    return(
      <Formik
        initialValues={{
          trade:'yes',
          fullName:'',
          email:'',
          phone:'',
          message:'',
          company:'',       //Trade only
          address:'',       //Trade only
          industry:'',      //Trade only
          buildNewHome:'',  //Non-trade only
          haveHydronic:'',  //Non-trade only
          updateSystem:'',  //Non-trade only
          formType: 'contact_submit',
          recaptcha:''}}
        validate={ values => {
          let errors = {}
          if(!values.fullName){
            errors.fullName = "Please enter your name."
          }

          if(!values.email){
            errors.email = "Please enter your email."
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Please enter a valid email."
          }

          if(!values.phone){
            errors.phone = "Please enter your phone."
            // } else if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(values.tel.replace(/\s-/g,''))) {
            //   errors.tel = "Please enter a valid phone number."
          } else if(values.phone.replace(/^0-9/g,'').length<10){
            errors.phone = "Please enter a valid Phone number."
          }

          if(values.trade==='yes'){
            if(!values.company){
              errors.company = "Please enter your company name."
            }
            if(!values.address){
              errors.address = "Please enter your business address."
            }
            if(!values.industry){
              errors.industry = "Please enter your industry."
            }
          }else{
            if(!values.buildNewHome){
              errors.buildNewHome = "Please indicate if you are building a new home."
            }
            if(!values.haveHydronic){
              errors.haveHydronic = "Please indicate if you have hydronic heating."
            }
            if(!values.updateSystem){
              errors.updateSystem = "Please indicate if you are updating a system."
            }
          }

          if(!values.message){
            errors.message = "Please enter a message."
          }

          // console.log('ERRORS')
          // console.log(errors)
          return errors

        }} onSubmit={((values, {setSubmitting}) => {

        //

        if(state.state !== state.states.SUBMITTED){
          state.state = state.states.SUBMITTED

          const submit = document.getElementById('contactSubmit')
          submit.classList.add('disabled')

          //Create the URL with value params for AWS Lambda
          let url = new URL(API_URL)
          Object.keys(values).forEach(key => url.searchParams.append(key,values[key]))
          //
          console.log('URL');
          console.log(url);
          console.log(url.search);

          fetch(url)
            .then( r => {
              switch(r.status){
                case state.responses.success:

                  const inputs = document.getElementsByClassName("label")
                  while(inputs.length>0){inputs[0].parentNode.removeChild(inputs[0])}

                  const grep = document.getElementById("g-recaptcha")
                  grep.parentNode.removeChild(grep)

                  document.getElementById('contact_presubmit').innerText = "Submitted! Thanks"
                  break;
                case state.responses.error:
                  //TODO: Display error message
                  state.state = state.states.READY
                  submit.classList.remove('disabled')
                  console.log('COULD NOT SEND')
                  console.log(r)
                  break;
              }

            })
            .catch( e => {console.log('Could not connect'); console.log(e);})
        }
        setSubmitting(false);

      } )} >
        {({
            errors, touched, setFieldValue, handleSubmit
          }) => (
          <Form id={`contact-us-form`} className={`trade`}>

            {!recaptchaInstance?<Recaptcha
              ref={ e => recaptchaInstance = e}
              sitekey="6LfoPIsUAAAAAAVz1C9UqXILyIQp1tA630jthcD_"
              verifyCallback={(res)=>{
                setFieldValue("recaptcha",res)
                handleSubmit()
              }}
              size="invisible"/>:``}

            <div className="form-row">
              <div className="radio-group">
                <label htmlFor="trade">Do you Represent a Trade?</label>

                <Field onClick={toggleTrade} type={`radio`} name="trade" value="yes" defaultChecked={true}/> Yes
                <Field onClick={toggleTrade} type={`radio`} name="trade" value="no" defaultChecked={false}/> No
              </div>
            </div>

            <div className="form-row">
              <div className="form-col">
                <div className="input-group">
                  <label onClick={onFocus} htmlFor="fullName">Full Name* <span className="error">{errors.fullName && touched.fullName && <div>{errors.fullName}</div>}</span></label>
                  <Field onFocus={onFocus} className="required"  type="text" name="fullName" />

                </div>
                <div className="input-group">
                  <label onClick={onFocus} htmlFor="email">Email* <span className="error">{errors.email && touched.email && <div>{errors.email}</div>}</span></label>
                  <Field onFocus={onFocus} className="required"  type="text" name="email" />
                </div>
                <div className="input-group">
                  <label onClick={onFocus} htmlFor="phone">Phone* <span className="error">{errors.phone && touched.phone && <div>{errors.phone}</div>}</span></label>
                  <Field onFocus={onFocus} className="required"  type="text" name="phone" />
                </div>
              </div>
              <div className="form-col trade">
                <div className="input-group">
                  <label onClick={onFocus} htmlFor="company">Company Name* <span className="error">{errors.company && touched.company && <div>{errors.company}</div>}</span></label>
                  <Field onFocus={onFocus} className="required"  type="text" name="company" />
                </div>
                <div className="input-group">
                  <label onClick={onFocus} htmlFor="address">Address, City, Province* <span className="error">{errors.address && touched.address && <div>{errors.address}</div>}</span></label>
                  <Field onFocus={onFocus} onFocus={onFocus} className="required"  type="text" name="address" />
                </div>
                <div className="input-group">
                  <label onClick={onFocus} htmlFor="industry">Industry* <span className="error">{errors.industry && touched.industry && <div>{errors.industry}</div>}</span></label>
                  <Field onFocus={onFocus} className="required"  type="text" name="industry" />
                </div>
              </div>

              <div className="form-col non-trade">
                <div className="radio-group">
                  <label htmlFor="buildNewHome">Are You Building a New Home?</label>
                  <span className="error">{errors.buildNewHome && touched.buildNewHome && <div>{errors.buildNewHome}</div>}</span>
                  <Field type="radio"  name="buildNewHome" value="yes"/> Yes
                  <Field type="radio"  name="buildNewHome" value="no"/> No
                </div>
                <div className="radio-group">
                  <label htmlFor="haveHydronic">Do You Currently Have Hydronic Heating?</label>
                  <span className="error">{errors.haveHydronic && touched.haveHydronic && <div>{errors.haveHydronic}</div>}</span>
                  <Field type="radio" name="haveHydronic" value="yes"/> Yes
                  <Field type="radio" name="haveHydronic" value="no"/> No
                </div>
                <div className="radio-group">
                  <label htmlFor="updateSystem">Are You Looking to Update a System?</label>
                  <span className="error">{errors.updateSystem && touched.updateSystem && <div>{errors.updateSystem}</div>}</span>
                  <Field type="radio" name="updateSystem" value="yes"/> Yes
                  <Field type="radio" name="updateSystem" value="no"/> No
                </div>
              </div>
            </div>
            <div className="form-row clearfix message">
              <div className="input-group ">
                <label onClick={onFocus} htmlFor="message">Nature of Your Interest:* <span className="error">{errors.message && touched.message && <div>{errors.message}</div>}</span></label>
                <Field onFocus={onFocus} component="textarea" className="required" name="message" id="message"/>

              </div>
              <div id="formError" className="error"/>
            </div>
            <div className="form-row submit-btn">
              <button id="contact_presubmit" className="submit" type="button" onClick={()=>{
                recaptchaInstance.execute()
              }}>Send Message</button>
              <button id="contactSubmit" className="hidden" type="submit">Actually Send Message</button>
            </div>
            <div className="form-disclaimer">
              <p>*All fields are required.</p>
            </div>

          </Form>
        )}

      </Formik>
    )
  }

}


const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />

    <h1>Contact Us</h1>
    <ContactForm/>

  </Layout>
)

export default ContactPage
